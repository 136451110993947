import React, { useState } from "react"
import { groupBy } from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import {
  Flex,
  Box,
  Card,
  Button,
  Container,
  Section,
} from "@singita/components"
import Main from "gatsby-singita-theme/src/components/Main"
import ImageWrapper from "../modules/ImageWrapper"
import Link from "../components/Link"
import FullModal from "../components/FullModal"
import DontationContainer from "../modules/Donation/DonationContainer"
import { Helmet, HelmetProvider } from "react-helmet-async"

const GlobalDonation = () => {
  const [modal, setModal] = useState({ isOpen: false, params: {} })
  const data = useStaticQuery(graphql`
    query GlobalDonationQuery {
      partners: allContentfulConservationPartner(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            ...ConservationPartnerFields
            emailId: eTapestryEmailReference
            fundId: eTapestryFundReference
            donationLink {
              label
              to
              external
              type
            }
            regions {
              headline
              country
            }
          }
        }
      }
    }
  `)

  const url = typeof window !== "undefined" ? window.location.href : ""

  const desc =
    "Singita is committed to building a financially sustainable conservation model that will see the continued success of our projects for the next 100 years. We believe that our lodges will continue to generate a substantial portion of the considerable cost of these projects but we are realistic about the challenges."

  return (
    <HelmetProvider>
      <Helmet>
        <title>Donate | How to Help | Singita</title>
        <link rel="canonical" href={"https://singita.com/donate/"} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Singita" />
        <meta property="og:description" content={desc} />
        <meta property="description" content={desc} name="description" />
      </Helmet>
      <Main>
        <Container>
          <Section
            pt={[15]}
            direction="row"
            headline="How to help"
            headlineSize="h1"
            description={desc}
            textContainerSx={{ pr: [0, 5] }}
          >
            <Flex flexDirection={"column"}>
              {data.partners.edges
                .filter(({ node }) => {
                  return node.donationLink || node.fundId
                })
                .map(({ node }) => {
                  const donationParams = {
                    fundId: node.fundId ?? "",
                    emailId: node.emailId ?? "",
                  }

                  // Group regions by country to avoid duplicates where there is more than one region in a Country
                  const grouped = groupBy(
                    node.regions,
                    ({ country }) => country
                  )
                  const country = Object.keys(grouped)[0]

                  return (
                    <Box mb={[2, 3]} width="100%" key={node.contentful_id}>
                      <Card
                        size="large"
                        variant="strip"
                        bg="baseWhite"
                        headline={node.headline}
                        meta={[
                          {
                            text: `${country}: ${grouped[country]
                              .map(r => r.headline)
                              .join(", ")}`,
                          },
                        ]}
                        renderImage={() => (
                          <ImageWrapper image={node?.visual?.image} />
                        )}
                        renderCta={() =>
                          donationParams.fundId ? (
                            <Button
                              variant="primary"
                              onClick={() =>
                                setModal({
                                  isOpen: true,
                                  params: donationParams,
                                })
                              }
                            >
                              Donate
                            </Button>
                          ) : node.donationLink ? (
                            <Link
                              colorScheme="brandBrown"
                              {...node.donationLink}
                            />
                          ) : null
                        }
                      />
                    </Box>
                  )
                })}
            </Flex>
          </Section>
        </Container>
        <FullModal
          isOpen={modal.isOpen}
          toggleModal={() => setModal({ isOpen: false, params: {} })}
          title={`Donate`}
        >
          <Box width={["100%", "100%", "100%", "100%", "50%"]} margin="auto">
            <DontationContainer {...modal.params} />
          </Box>
        </FullModal>
      </Main>
    </HelmetProvider>
  )
}

export default GlobalDonation
